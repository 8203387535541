import React from 'react';
import Layout from '../components/layout/Layout';
import { Helmet } from "react-helmet"

export default function Example() {
  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>C4Scale - Privacy policy</title>
          <meta name="description" content="Privacy policies at C4Scale" />
        </Helmet>
    <section className="lg:p-20 p-4">
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mt-2 block text-3xl text-center text-indigo-800 leading-8 font-extrabold tracking-tigh sm:text-4xl">
              Privacy Policy for C4Scale
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-700 leading-8">
            At C4Scale.com, accessible from C4Scale.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by C4Scale.com and how we use it.
          </p>
        </div>
        <p className="mt-8 text-xl text-gray-700 leading-8">
           If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
          </p>
          <p className="mt-8 text-xl text-gray-700 leading-8">
            This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in C4Scale.com. This policy is not applicable to any information collected offline or via channels other than this website.
          </p>
          <h1 style={{ marginTop: '2rem' }}>
            <span className="mt-2 block text-3xl text-indigo-800 leading-8 font-extrabold tracking-tigh sm:text-4xl">
              Consent
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-700 leading-8">
            By using our website, you hereby consent to our Privacy Policy and agree to its terms.
          </p>
        <h1 style={{ marginTop: '2rem' }}>
            <span className="mt-2 block text-3xl text-indigo-800 leading-8 font-extrabold tracking-tigh sm:text-4xl">
              Information we collect
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-700 leading-8">
The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
          </p>
          <p className="mt-8 text-xl text-gray-700 leading-8">
If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
          </p>
          <p className="mt-8 text-xl text-gray-700 leading-8">
When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
          </p>
          <h1 style={{ marginTop: '2rem' }}>
            <span className="mt-2 block text-3xl text-indigo-800 leading-8 font-extrabold tracking-tigh sm:text-4xl">
             How we use your information
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-700 leading-8">
We use the information we collect in various ways, including to:
          </p>
          <ul style={{listStyleType: 'disc', margin: '1.6rem'}}>
              <li>Provide, operate, and maintain our website</li>
              <li>Improve, personalize, and expand our website</li>
              <li>Understand and analyze how you use our website</li>
              <li>Develop new products, services, features, and functionality</li>
              <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
              <li>Send you emails</li>
              <li>Find and prevent fraud</li>
          </ul>
          <h1 style={{ marginTop: '2rem' }}>
            <span className="mt-2 block text-3xl text-indigo-800 leading-8 font-extrabold tracking-tigh sm:text-4xl">
             Log Files
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-700 leading-8">
C4Scale.com follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
          </p>
          <h1 style={{ marginTop: '2rem' }}>
            <span className="mt-2 block text-3xl text-indigo-800 leading-8 font-extrabold tracking-tigh sm:text-4xl">
             Cookies
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-700 leading-8">
C4Scale.com <b> does not use </b> 'cookies'. However it may use cookies in future to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.
          </p>
          {/* <p className="mt-8 text-xl text-gray-700 leading-8">
For more general information on cookies, please read <a href="https://www.cookieconsent.com/what-are-cookies/">What Are Cookies" from Cookie Consent</a>.
          </p> */}
          <h1 style={{ marginTop: '2rem' }}>
            <span className="mt-2 block text-3xl text-indigo-800 leading-8 font-extrabold tracking-tigh sm:text-4xl">
             Advertising Partners Privacy Policies
            </span>
          </h1>
           <p className="mt-8 text-xl text-gray-700 leading-8">
You may consult this list to find the Privacy Policy for each of the advertising partners of C4Scale.com.
          </p>
          <p className="mt-8 text-xl text-gray-700 leading-8">
Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on C4Scale.com, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
          </p>
          <p className="mt-8 text-xl text-gray-700 leading-8">
Note that C4Scale.com has no access to or control over these cookies that are used by third-party advertisers.
          </p>
          <h1 style={{ marginTop: '2rem' }}>
            <span className="mt-2 block text-3xl text-indigo-800 leading-8 font-extrabold tracking-tigh sm:text-4xl">
             Third Party Privacy Policies
            </span>
          </h1>
           <p className="mt-8 text-xl text-gray-700 leading-8">
C4Scale.com's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
          </p>
           <p className="mt-8 text-xl text-gray-700 leading-8">
You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
          </p>
          {/* <h1 style={{ marginTop: '2rem' }}>
            <span className="mt-2 block text-3xl text-indigo-800 leading-8 font-extrabold tracking-tigh sm:text-4xl">
             CCPA Privacy Rights (Do Not Sell My Personal Information)
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-700 leading-8">
Under the CCPA, among other rights, California consumers have the right to:
          </p>
          <p className="mt-8 text-xl text-gray-700 leading-8">
Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
          </p>
           <p className="mt-8 text-xl text-gray-700 leading-8">
Request that a business delete any personal data about the consumer that a business has collected.
          </p>
          <p className="mt-8 text-xl text-gray-700 leading-8">
Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
          </p>
          <p className="mt-8 text-xl text-gray-700 leading-8">
If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
          </p>
          <h1 style={{ marginTop: '2rem' }}>
            <span className="mt-2 block text-3xl text-indigo-800 leading-8 font-extrabold tracking-tigh sm:text-4xl">
GDPR Data Protection Rights
            </span>
          </h1>
           <p className="mt-8 text-xl text-gray-700 leading-8">
We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
          </p>
          <p className="mt-8 text-xl text-gray-700 leading-8">
The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
          </p>
          <p className="mt-8 text-xl text-gray-700 leading-8">
The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
          </p>
          <p className="mt-8 text-xl text-gray-700 leading-8">
The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
          </p>
          <p className="mt-8 text-xl text-gray-700 leading-8">
The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
          </p>
          <p className="mt-8 text-xl text-gray-700 leading-8">
The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
          </p>
          <p className="mt-8 text-xl text-gray-700 leading-8">
The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
          </p>
           <p className="mt-8 text-xl text-gray-700 leading-8">
If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
          </p> */}
          <h1 style={{ marginTop: '2rem' }}>
            <span className="mt-2 block text-3xl text-indigo-800 leading-8 font-extrabold tracking-tigh sm:text-4xl">
Children's Information
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-700 leading-8">
Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
          </p>
          <p className="mt-8 text-xl text-gray-700 leading-8">
C4Scale.com does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
          </p>
      </div>
    </div>
    </section>
    </Layout>
  )
}
